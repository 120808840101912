/* ComponentModal.scss */

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000;
}

.modal-content {
  background-color: white; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  max-height: 90vh; /* Max-height to allow scrolling */
  overflow-y: auto; /* Make content scrollable */
  overflow-x: hidden; /* Prevent horizontal scroll */
  animation: fadeIn 0.3s ease-out; /* Optional fade in effect */
}

.modal-inner-content {
  max-width: 1200px; /* Set a max-width for inner content to ensure readability */
  margin: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #111;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ExperienceModal.scss */

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it sits above other elements */
}

.modal-content {
  background-color: white; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 80%;
  max-height: 90vh; /* Adjust max height to allow scrolling */
  margin: auto;
  overflow-y: auto; /* Ensure content is scrollable */
  animation: fadeIn 0.3s ease-out; /* Optional fade-in effect */
}

.modal-inner-content {
  max-width: 1200px; /* Set a max-width for inner content to ensure readability */
  margin: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #111;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Ensure each image takes its own line */
.popup-image {
  display: block;
  margin: 20px 0; /* Space between images */
  width: 100%; /* Adjust or set max-width as necessary */
  height: auto;
  border-radius: 8px;
}
/* ExperienceModal.scss */

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it sits above other elements */
}

.modal-content {
  background-color: white; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 80%;
  max-height: 90vh; /* Adjust max height to allow scrolling */
  margin: auto;
  overflow-y: auto; /* Ensure content is scrollable */
  animation: fadeIn 0.3s ease-out; /* Optional fade-in effect */
}

.modal-inner-content {
  max-width: 1200px;
  margin: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #111;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.project-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popup-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}